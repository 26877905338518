/**
 * A quick check to see if the string looks like an email address
 *
 * @param {string} email - Email to validate
 * @returns {boolean} If the email appears valid
 */
export function validateEmailSimple(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

/**
 * A fairly thorough check to make sure that the email address is correctly written
 *
 * @param {string} email - Email to validate
 * @returns {boolean} If the email appears valid
 */
export function validateEmail(email) {
    const re =
        /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    return re.test(email);
}

/**
 * A decently thorough check to make sure that the email address is correctly written
 *
 * @param {string} email - Email to validate
 * @returns {boolean} If the email appears valid
 */
export function validateEmailRegex(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
